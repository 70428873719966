const ChapterExtrasConnection = () => (
    <div className="h-12 w-12">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            className="block h-full w-full rotate-90 md:rotate-0 text-white opacity-50 overflow-visible"
            viewBox="0 0 128 512"
            preserveAspectRatio="xMidYMid slice"
            aria-hidden
        >
            <path
                stroke="#fff"
                strokeLinecap="square"
                strokeWidth="24"
                d="M116 12C20 12 96 252 12 252M12 252c96 0 20 248 104 248"
            />
        </svg>
    </div>
);

export default ChapterExtrasConnection;
