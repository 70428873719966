'use client';

import { Subject } from '@/hooks/useSubject';
import { useStrings } from '@/resources/translations/translations';
import { useEffect, useMemo, useRef, useState } from 'react';
import { motion, useReducedMotion } from 'framer-motion';
import { calculateSubjectProgressPercentage } from '@/hooks/useSubjectProgress';
import { twMerge } from 'tailwind-merge';

export const SubjectProgressFromPageId = ({
    subject,
    pageId,
    className,
}: {
    subject: Subject;
    pageId: number;
    className?: string;
}) => {
    const percentage = useMemo(
        () => calculateSubjectProgressPercentage(subject, pageId),
        [pageId],
    );

    return (
        <SubjectProgressMeter
            percentage={percentage}
            title={subject.title}
            pageId={pageId}
            className={className}
        />
    );
};

export const SubjectProgressFromLocalProgress = ({
    subject,
    className,
}: {
    subject: Subject;
    className?: string;
}) => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    const percentage = calculateSubjectProgressPercentage(subject);

    return (
        <SubjectProgressMeter
            percentage={isClient ? percentage : 0}
            title={subject.title}
            pageId={0}
            className={className}
        />
    );
};

const SubjectProgressMeter = ({
    title,
    percentage,
    pageId,
    className,
}: {
    title: string;
    percentage: number;
    pageId: number;
    className?: string;
}) => {
    const strings = useStrings('components.SubjectProgress');
    const reduceMotion = useReducedMotion();

    const meterValueRef = useRef<HTMLSpanElement>(null);

    return (
        <div
            role="meter"
            aria-label={strings('meter_label', { subject: title })}
            title={strings('meter_label', { subject: title })}
            aria-valuemin={0}
            aria-valuemax={100}
            aria-valuenow={percentage}
            aria-valuetext={`${percentage}%`}
            className={twMerge(
                'relative w-full p-1 bg-white/20 shadow-inner-lg rounded-full font-bold',
                className,
            )}
        >
            <div className="overflow-hidden rounded-full">
                <motion.div
                    className="px-6 py-1 min-w-fit text-center bg-gradient-to-r from-keppel-text-lg-aa to-secondary-text-base-aa text-white rounded-full shadow-md origin-left"
                    initial={{
                        width: `${percentage}%`,
                        scaleX: !reduceMotion && percentage === 0 ? 0 : 1,
                        scaleY: !reduceMotion && percentage === 0 ? 0 : 0.5,
                    }}
                    animate={{
                        width: `${percentage}%`,
                        scaleX: 1,
                        scaleY: 1,
                        transition: {
                            duration: reduceMotion ? 0 : 1,
                        },
                    }}
                    layoutId={
                        reduceMotion
                            ? undefined
                            : `subject-progress-meter-${title}`
                    }
                    layoutDependency={pageId}
                    aria-hidden
                >
                    <motion.span
                        ref={meterValueRef}
                        layoutId={
                            reduceMotion
                                ? undefined
                                : `subject-progress-meter-value-${title}`
                        }
                        layoutDependency={pageId}
                        className="filter drop-shadow-md"
                        aria-hidden
                    >
                        {percentage}%
                    </motion.span>
                </motion.div>
            </div>
        </div>
    );
};
