'use client';

import useBackgroundGradient from '@/hooks/useBackgroundGradient';
import { BackgroundColors } from '@/resources/Colors';
import {
    ComponentPropsWithoutRef,
    ElementType,
    ReactNode,
    Suspense,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { HomeItemData } from '@/components/HomeItems/HomeItems';

export const BackgroundOverlay = () => (
    <div className="fixed inset-0 h-full w-full overflow-hidden">
        <div className="absolute -top-[10vh] -left-[10vh] lg:left-0 xl:left-[10vw] w-[80vh] lg:w-[100vh] h-[80vh] lg:h-[100vh] bg-gradient-radial from-rose-500/30 via-secondary-500/0 to-secondary-500/0 scale-150" />
        <div className="absolute -bottom-[20vh] -right-[10vh] lg:right-0 xl:right-[10vw] w-[80vh] lg:w-[100vh] h-[80vh] lg:h-[100vh] bg-gradient-radial from-blumine-200/30 via-secondary-500/0 to-secondary-500/0 scale-150" />
    </div>
);

interface PageGradientBackgroundProps<T extends ElementType> {
    as?: T;
    homeItems: HomeItemData[];
    className?: string;
    children?: ReactNode;
}

export const PageGradientBackground = <T extends ElementType = 'div'>(
    props: PageGradientBackgroundProps<T> &
        Omit<ComponentPropsWithoutRef<T>, keyof PageGradientBackgroundProps<T>>,
) => {
    return (
        <Suspense>
            <PageGradientBackgroundInternal {...props} />
        </Suspense>
    );
};

const PageGradientBackgroundInternal = <T extends ElementType = 'div'>({
    as,
    homeItems,
    className,
    ...props
}: PageGradientBackgroundProps<T> &
    Omit<
        ComponentPropsWithoutRef<T>,
        keyof PageGradientBackgroundProps<T>
    >) => {
    const Component = as ?? 'div';

    const backgroundGradient = useBackgroundGradient(homeItems);

    return (
        <Component
            className={twMerge(
                backgroundGradient,
                backgroundGradient
                    ? 'bg-gradient-to-b'
                    : BackgroundColors.default,
                className,
            )}
            {...props}
        />
    );
};
