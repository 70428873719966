'use client';

import { SubjectProgressFromLocalProgress } from '../Subject/SubjectProgress';
import Image from 'next/image';
import ChapterExtrasConnection from './ChapterExtrasConnection';
import styles from './HomeItems.module.sass';
import EmojiText from '../EmojiText/EmojiText';
import Link from 'next/link';
import HomePrimaryButton from './HomeItemPrimaryButton/HomeItemPrimaryButton';
import { HomeItemData } from './HomeItems';
import { getLastFinishedPageUrl } from '@/hooks/useSubjectProgress';
import { Suspense, useEffect, useState } from 'react';
import { measure } from '../Analytics/Analytics';
import Icon from '@mdi/react';
import { mdiVideo } from '@mdi/js';

export const HomeItem = ({
    item,
    index,
}: {
    item: HomeItemData;
    index: number;
}) => {
    const [isClient, setIsClient] = useState(false);
    const [isMainImageLoaded, setIsMainImageLoaded] = useState(false);

    useEffect(() => {
        setIsClient(true);

        // Remove 'from' query parameter from URL without refreshing the page
        const url = new URL(window.location.href);
        url.searchParams.delete('from');
        window.history.replaceState({}, '', url.toString());
    }, []);

    const defaultUrl = `/story/${item.subject.id}`;
    const url = getLastFinishedPageUrl(item.subject);

    const titleSplitByMostCenteredSpace = item.subject.title.split(
        /\s(?=\S*$)/,
    );

    return (
        <li
            className={`${styles['homeitem']} pt-16 md:ps-8 lg:ps-32`}
            style={{
                animationDelay: `${index * 0.3}s`,
            }}
        >
            <div className="flex flex-col md:flex-row items-center justify-center">
                <article className="w-full max-w-md">
                    <Link
                        passHref
                        className="group flex flex-col aspect-square w-full max-h-[95vh]"
                        href={isClient ? url : defaultUrl}
                        title={`Start met '${item.subject.title}'`}
                        onClick={() => measure(defaultUrl, 'navigation')}
                    >
                        <div
                            className={`relative grow flex flex-col bg-gradient-to-b ${item.colors.from} ${item.colors.to} rounded-3xl shadow-lg motion-safe:hover:scale-[1.02] motion-safe:active:scale-100 transition-all z-10`}
                        >
                            <div
                                className={`${styles['homeitem__number']} absolute left-1/2 -translate-x-1/2 -translate-y-20 h-12 w-12 ${item.colors.bgDark} text-white shadow-lg font-bold text-4xl rounded-full`}
                            >
                                <div className="text-center translate-y-1">
                                    {index + 1}
                                </div>
                            </div>
                            <h2
                                className={`relative w-full max-w-sm mx-auto pt-4 ${item.colors.textDark} font-bold text-2xl md:text-4xl text-center text-balance -translate-y-9 scale-125 md:scale-100 md:motion-safe:group-hover:scale-105 md:motion-safe:group-hover:-translate-y-8 transition-all duration-700 z-10`}
                            >
                                <svg
                                    viewBox="0 0 150 140"
                                    className="relative h-24 w-full mx-auto filter drop-shadow-md overflow-visible z-10"
                                    aria-hidden
                                >
                                    <text
                                        x="50%"
                                        y="28%"
                                        dominantBaseline="middle"
                                        textAnchor="middle"
                                        fill="currentColor"
                                        stroke="currentColor"
                                        strokeWidth="9%"
                                        fontSize="200%"
                                        strokeLinejoin="round"
                                        strokeLinecap="round"
                                        paintOrder="stroke"
                                        aria-hidden
                                    >
                                        {titleSplitByMostCenteredSpace[0]}
                                    </text>
                                    <text
                                        x="50%"
                                        y="28%"
                                        dominantBaseline="middle"
                                        textAnchor="middle"
                                        fill="white"
                                        fontSize="200%"
                                        aria-hidden
                                    >
                                        {titleSplitByMostCenteredSpace[0]}
                                    </text>
                                    <text
                                        x="50%"
                                        y="80%"
                                        dominantBaseline="middle"
                                        textAnchor="middle"
                                        fill="currentColor"
                                        stroke="currentColor"
                                        strokeWidth="9%"
                                        fontSize="200%"
                                        strokeLinejoin="round"
                                        strokeLinecap="round"
                                        paintOrder="stroke"
                                        aria-hidden
                                    >
                                        {titleSplitByMostCenteredSpace[1]}
                                    </text>
                                    <text
                                        x="50%"
                                        y="80%"
                                        dominantBaseline="middle"
                                        textAnchor="middle"
                                        fill="white"
                                        fontSize="200%"
                                        aria-hidden
                                    >
                                        {titleSplitByMostCenteredSpace[1]}
                                    </text>
                                </svg>
                                <span className="absolute inset-0 w-full h-full text-transparent">
                                    {item.subject.title}
                                </span>
                            </h2>
                            <div className="absolute inset-0 w-full h-full object-cover rounded-3xl overflow-hidden">
                                <Image
                                    priority={index < 2}
                                    placeholder="blur"
                                    src={item.image.src}
                                    alt={item.image.alt}
                                    width={384}
                                    quality={100}
                                    onLoad={() => setIsMainImageLoaded(true)}
                                    className="absolute w-full h-full object-cover bg-white/10 group-hover:bg-secondary/30 motion-safe:group-hover:scale-110 transition-all duration-700"
                                    style={{
                                        maskImage:
                                            'linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,1), rgba(0,0,0,1), rgba(0,0,0,0.3), rgba(0,0,0,0))',
                                    }}
                                />
                            </div>
                            <div className="grow" />
                            <div
                                className={`relative z-10 flex flex-col bg-gradient-to-b ${item.colors.bottomOverlayFromTo} rounded-b-3xl`}
                            >
                                <Suspense>
                                    <HomePrimaryButton subject={item.subject} />
                                </Suspense>
                            </div>
                        </div>
                        <div
                            className={`${styles['homeitem__progress']} mx-6 p-2 bg-gradient-to-b from-white to-secondary-200 shadow-lg rounded-t-none rounded-b-3xl`}
                        >
                            <SubjectProgressFromLocalProgress
                                subject={item.subject}
                                className="bg-gradient-to-b from-secondary-100 to-white shadow-none"
                            />
                        </div>
                    </Link>
                </article>
                <ChapterExtrasConnection />
                <aside
                    aria-label={`Extra's bij ${item.subject.title}`}
                    className="relative max-md:w-full max-md:max-w-[24rem]"
                >
                    <h3 className="max-md:sr-only md:block pb-3 ps-6 text-2xl text-white font-bold filter drop-shadow-md">
                        Extra&apos;s
                    </h3>
                    <ul className="grid gap-2 sm:gap-4 md:gap-6 grid-cols-2 md:grid-cols-1 w-full max-w-[24rem] md:w-[12rem]">
                        {item.extras.map((extra, index) => (
                            <HomeItemExtra
                                extra={extra}
                                item={item}
                                isVideo={extra.path.startsWith('/kijken?')}
                                isMainImageLoaded={isMainImageLoaded}
                                index={index}
                                key={extra.path}
                            />
                        ))}
                    </ul>
                </aside>
            </div>
        </li>
    );
};
const HomeItemExtra = ({
    extra,
    item,
    isVideo,
    isMainImageLoaded,
    index,
}: {
    extra: HomeItemData['extras'][0];
    item: HomeItemData;
    isVideo: boolean;
    isMainImageLoaded: boolean;
    index: number;
}) => (
    <li
        key={extra.path}
        className={`${styles['homeitem__extra']} relative w-full h-full aspect-[2/1.75] bg-secondary-900 rounded-xl sm:rounded-3xl shadow-lg motion-safe:hover:scale-105 motion-safe:active:scale-100 transition-all z-10`}
    >
        <Link
            passHref
            href={extra.path}
            title={extra.title}
            className="group flex items-end justify-start h-full w-full"
            prefetch={false}
            onClick={() => measure(extra.path.replace('?', ''), 'navigation')}
        >
            <div className="relative w-full z-10">
                <h4
                    className={`relative max-sm:w-full w-fit bg-white ${item.colors.textDark} sm:m-2 px-3 py-1.5 sm:py-1 text-base font-bold text-balance rounded-b-xl sm:rounded-2xl shadow-md motion-safe:transition-all z-[initial]`}
                >
                    {isVideo && (
                        <div
                            className={`absolute -top-7 left-4 flex gap-1 px-1 py-0.5 ${item.colors.bgDark} text-sm text-white rounded-t-lg shadow-lg`}
                            aria-label="Video afspelen"
                            role="img"
                        >
                            <Icon path={mdiVideo} size={1} />
                        </div>
                    )}
                    {extra.title}
                </h4>
            </div>
            {!!extra.image && (
                <div
                    className="absolute inset-0 w-full h-full object-cover rounded-xl sm:rounded-3xl overflow-hidden transition-opacity duration-500"
                    style={{
                        opacity: isMainImageLoaded ? 1 : 0,
                        transitionDelay: `${index * 0.2}s`,
                    }}
                >
                    <Image
                        src={extra.image.src}
                        alt={extra.image.alt}
                        width={256}
                        quality={50}
                        placeholder="blur"
                        className="w-full h-full object-cover motion-safe:group-hover:scale-[1.02] transition-all duration-500"
                    />
                </div>
            )}
            <span
                className="absolute end-2 -top-4 lg:-top-3.5 text-4xl rotate-6 scale-110 lg:scale-125 motion-safe:group-hover:scale-125 transition-all duration-300 z-20"
                aria-hidden
            >
                <EmojiText>{extra.emoji}</EmojiText>
            </span>
        </Link>
    </li>
);
