'use client';

import TextButton from '@/components/Button/TextButton';
import { Subject } from '@/hooks/useSubject';
import { calculateSubjectProgressPercentage } from '@/hooks/useSubjectProgress';
import { mdiArrowRightCircle } from '@mdi/js';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import { motion, useReducedMotion } from 'framer-motion';
import { useSearchParams } from 'next/navigation';

const HomePrimaryButton = ({ subject }: { subject: Subject }) => {
    const reduceMotion = useReducedMotion();
    const selfRef = useRef<HTMLDivElement>(null);
    const searchParams = useSearchParams();

    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    const percentage = calculateSubjectProgressPercentage(subject);

    const isStarted = percentage > 0;
    const isFinished = percentage === 100;
    const isJustFinished =
        searchParams.get('finished-story') === subject.id.toString();
    const playJustFinishedAnimation = !reduceMotion && isJustFinished;

    useEffect(() => {
        if (isJustFinished && selfRef.current) {
            selfRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });

            // Remove finished-story query param from URL without refreshing the page after 5 seconds
            setTimeout(() => {
                const url = new URL(window.location.href);
                url.searchParams.delete('finished-story');
                window.history.replaceState({}, '', url.toString());
            }, 5000);
        }
    }, [selfRef.current, isJustFinished]);

    if (!isClient) return;

    return (
        <div ref={selfRef} className="relative pb-8 pt-2">
            <TextButton
                icon={mdiArrowRightCircle}
                className={
                    isFinished
                        ? 'from-secondary-text-base-aa to-secondary-text-base-aaa'
                        : !isStarted
                        ? 'gap-3 text-2xl'
                        : undefined
                }
                isSmall={percentage === 100}
                tabIndex={-1}
            >
                {!isStarted ? 'start!' : isFinished ? 'bekijken' : 'verder!'}
            </TextButton>
            {isFinished && (
                <motion.div
                    className="absolute w-20 sm:w-28 h-20 sm:h-28 end-4 sm:end-0 -bottom-8 sm:-bottom-12"
                    initial={{
                        opacity: 0,
                        scale: playJustFinishedAnimation ? 0 : 1,
                        rotate: playJustFinishedAnimation ? -2 : 12,
                    }}
                    animate={{
                        opacity: [0, 1, 1, 1, 1],
                        scale: playJustFinishedAnimation
                            ? [2.5, 0.8, 1.4, 0.9, 1.05, 1]
                            : 1,
                        rotate: 12,
                        transition: {
                            duration: 3,
                            delay: playJustFinishedAnimation ? 1 : 0,
                        },
                    }}
                >
                    <div className="relative w-full h-full">
                        <motion.div
                            className="absolute w-full h-full pointer-events-none"
                            initial={{
                                rotate: playJustFinishedAnimation ? -90 : -45,
                                scale: 0.5,
                            }}
                            animate={{
                                rotate: 0,
                                scale: reduceMotion ? 0 : [0.7, 1.1, 1, 0.5],
                                transition: {
                                    duration: playJustFinishedAnimation
                                        ? 10
                                        : 5,
                                },
                            }}
                        >
                            <Image
                                className="w-full h-full scale-150"
                                src={require('./shine.svg')}
                                alt=""
                                width={100}
                                height={100}
                            />
                        </motion.div>
                        <Image
                            className="absolute w-full h-full"
                            src={require('./badge.svg')}
                            alt="Badge"
                            width={100}
                            height={100}
                        />
                    </div>
                </motion.div>
            )}
        </div>
    );
};

export default HomePrimaryButton;
