import { usePathname, useSearchParams } from 'next/navigation';
import { HomeItemData } from '@/components/HomeItems/HomeItems';
import { useMemo } from 'react';

const useBackgroundGradient = (homeItems: HomeItemData[]) => {
    const pathname = usePathname();
    const searchParams = useSearchParams();

    const fullPathWithoutLocale = useMemo(
        () => pathname.replace(/^\/[a-z]{2}/, ''),
        [pathname],
    );

    const fullPathWithoutLocaleWithSearchParams = useMemo(
        () =>
            [fullPathWithoutLocale, `?${searchParams}`]
                .filter((part) => part.toString().length > 1)
                .join(''),
        [fullPathWithoutLocale, searchParams],
    );

    const bestMatchFromHomeItems = useMemo(() => {
        let longestMatchingPath = '';
        let fullPageGradient = '';

        if (fullPathWithoutLocaleWithSearchParams.length <= 1) return '';

        // Search for the best match (current path starts with the item path or an item extra path)
        homeItems.forEach((item) => {
            const itemPath = item.path;
            const matchingExtraPaths = item.extras.map((extra) => extra.path);
            const pathsToCheck = [itemPath, ...matchingExtraPaths];

            // Check if first part of the path matches any of the item paths
            const matchingPaths = pathsToCheck
                .filter(
                    (path) =>
                        (fullPathWithoutLocaleWithSearchParams.startsWith(
                            path.slice(0, 4),
                        ) ||
                            fullPathWithoutLocaleWithSearchParams.startsWith(
                                path,
                            )) &&
                        path.length > longestMatchingPath.length,
                )
                // Put longest matches first
                .sort((a, b) => b.length - a.length);

            if (
                matchingPaths.length &&
                matchingPaths[0].length > longestMatchingPath.length
            ) {
                longestMatchingPath = matchingPaths[0];
                fullPageGradient = item.colors.fullPageGradient;
            }
        });

        return fullPageGradient;
    }, [fullPathWithoutLocale, fullPathWithoutLocaleWithSearchParams]);

    return bestMatchFromHomeItems;
};

export default useBackgroundGradient;
